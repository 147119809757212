// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-properties-commercial-for-rent-index-js": () => import("./../../../src/pages/properties/commercial/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-commercial-for-rent-index-js" */),
  "component---src-pages-properties-commercial-for-sale-index-js": () => import("./../../../src/pages/properties/commercial/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-commercial-for-sale-index-js" */),
  "component---src-pages-properties-for-rent-index-js": () => import("./../../../src/pages/properties/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-for-rent-index-js" */),
  "component---src-pages-properties-for-sale-index-js": () => import("./../../../src/pages/properties/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-for-sale-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-area-guide-template-js": () => import("./../../../src/templates/area-guide-template.js" /* webpackChunkName: "component---src-templates-area-guide-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-home-page-template-js": () => import("./../../../src/templates/home-page-template.js" /* webpackChunkName: "component---src-templates-home-page-template-js" */),
  "component---src-templates-off-plan-details-template-js": () => import("./../../../src/templates/off-plan-details-template.js" /* webpackChunkName: "component---src-templates-off-plan-details-template-js" */),
  "component---src-templates-off-plan-properties-template-js": () => import("./../../../src/templates/off-plan-properties-template.js" /* webpackChunkName: "component---src-templates-off-plan-properties-template-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-team-details-template-js": () => import("./../../../src/templates/team-details-template.js" /* webpackChunkName: "component---src-templates-team-details-template-js" */)
}

