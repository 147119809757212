import React from "react"
import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();
export { wrapRootElement } from "./src/apollo/wrap-root-element";

export const onRouteUpdate = ({ location, prevLocation }) => { 
  // if (location.pathname.split('?').length > 0) {
  //   window.history.replaceState(null, '', location.pathname);
  // } 
  //console.log("new pathname", location)
  //console.log("old pathname", prevLocation?.search?.indexOf("utm_source"))

  if(prevLocation?.search?.indexOf("utm_source") > 0){
    window.history.replaceState(null, '', prevLocation.search);
    //window.location = prevLocation.search;
  }
}

// export const onPreRouteUpdate = ({ location, prevLocation }) => {
//   console.log("Gatsby started to change location to", location)
//   console.log("Gatsby started to change prevLocation from", prevLocation)

//   if(prevLocation){

//     if(location?.search?.indexOf("utm_source")){
//       //window.history.replaceState({search:location?.search}, location?.search, location?.search);
//       window.location = location.search;
//     }
//   }
// }

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  // Avoid to scroll to top when next page trigger click
  // But this feature should condiser with details page back scroll too
  if (location.pathname.match(/properties\/for-sale/)) {
    return false;
  }
  if (location.pathname.match(/properties\/for-rent/)) {
    return false;
  }

  if (location.pathname.match(/properties\/commercial\/for-sale/)) {
    return false;
  }
  if (location.pathname.match(/properties\/commercial\/for-rent/)) {
    return false;
  }


  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/property-news`, `/property-news/sales`, `/property-news/leasing`, `/property-news/market`, `/property-news/living-in-dubai`]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    return false
  }


  return true;
};
